<template>
  <v-app style="background-color:#f8f9fb" >

        <!-- Diálogo de progresso -->
  <v-dialog v-model="showProgress" persistent max-width="300">
    <v-card>
      <v-card-title>
        Baixando arquivo...
      </v-card-title>
      <v-card-text>
        <!-- Coloque aqui qualquer indicador de progresso que você deseja mostrar -->
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>


  <v-dialog v-model="showFilter" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        A Diferença entre as datas não pode ser superior a {{ this.dias }} dias.
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilter = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="showFilterDownload" persistent max-width="300">
    <v-card>
      <v-card-title>
       Mensagem
      </v-card-title>
      <v-card-text class="d-flex justify-center">
        Em Manutenção
      </v-card-text>
      <v-card-actions class="d-flex justify-center"> <!-- Centraliza horizontalmente -->
        <v-btn color="primary" @click="showFilterDownload = false">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


    <v-container style="background-color:#f8f9fb;" >
      <v-col cols="12">
        <filtro-component :opcoes="opcoes" :status="status" :produtos="tipos_funerarios" :modos="modos"  :tipos="tipos" :calendar="'decadas'"
        @filtrar="filterData" :title_opcao="'Campanha'" :campos="'N'"
          ></filtro-component>
      </v-col>
    </v-container>
    <v-row>

      <v-col
      cols="12"
      sm="3"
      lg="3">

    </v-col>
    
      <v-col
      cols="12"
      sm="3"
      lg="3">
      
      <base-material-stats-card 
     color="orange"
     icon="mdi-alert"
     title="Total"
     sub-icon-color="red"
     :valuex='false'
     :value='(this.total_carteira)'
     :sub-text="'Total da Carteira'"
   />
      
     </v-col>
  
  
     <v-col
     cols="12"
     sm="3"
     lg="3">

     <base-material-stats-card 
     color="orange"
     icon="mdi-alert"
     title="Total"
     sub-icon-color="red"
     :valuex='false'
     :value='(this.total_titular)'
     :sub-text="'Total de Óbitos de Titulares'"
   />
   
    
  </v-col>
  
  
    <v-col
     cols="12"
     sm="3"
     lg="3">
     
      <base-material-stats-card 
        color="orange"
        icon="mdi-alert"
        title="Total"
        sub-icon-color="red"
        :valuex='false'
        :value='(this.total_naotitular)'
        :sub-text="'Total de Óbitos de Dep. e Agreg.'"
        />
  
  
    </v-col>
  
  
  
    </v-row>

    <br/>
   
    <v-divider></v-divider>
    
    <br/><br/>


  <v-container style="background-color:#eee"  
    id="financeiro"
    fluid
    tag="section">
   
    
    <v-row>
   
  
    <v-col
    cols="12">

        <base-material-card
        icon="mdi-alert"
        title="Sinistralidade"
        color="orange" style="margin-top:20px !important; padding-top:10px !important"
        class="px-5 py-0"
          > 

              <v-data-table
              :headers="ranking_header"
              :items="lista_sinistro"
              :items-per-page="20" 
              items-per-page-text="Linhas por página"
              class="elevation-0">
      
              <br/> <br/> <br/>
  
              <template v-slot:top>
                <v-progress-linear
                  v-if="isLoading" 
                  indeterminate
                  color="orange"
                ></v-progress-linear>
              </template>

                        <template v-slot:item="row">
                          
                          <tr :class="{'cor-row': row.index % 2 !== 0}">
                                
                                <td class="linha" style="width: 20%;font-size:14px;"> 
                                  {{row.item.plano}}
                                </td>

                                <td class="linha" style="width:10%;font-size:14px;">
                                  <v-chip 
                                  :color="getStatus(row.item.empresa)"
                                  text-color="white">
                                      {{row.item.empresa}}                    
                                      
                                  </v-chip>  
                                </td>

                                <td class="linha" style="width: 10%;font-size:14px;"> 
                                  {{row.item.carteira}}
                                </td>

                                <td class="linha" style="width: 10%;font-size:14px;"> 
                                  {{row.item.titular}}
                                </td>

                             

                                <td class="linha" style="width: 10%;font-size:14px;"> 
                                  {{ isNaN(row.item.titular / total_titular) ? '0.0000' : (row.item.titular / total_titular).toFixed(4) }}%
                                </td>
                                
                                
                                <td class="linha" style="width: 10%;font-size:14px;"> 
                                  {{ isNaN(row.item.titular / row.item.carteira) ? '0.0000' : (row.item.titular / row.item.carteira).toFixed(4) }}%
                                </td>

                                <td class="linha" style="width: 10%;font-size:14px;"> 
                                  {{row.item.naotitular}}
                                </td>

                                <td class="linha" style="width: 10%;font-size:14px;"> 
                                  {{ isNaN(row.item.naotitular / total_naotitular) ? '0.0000' : (row.item.naotitular / total_naotitular).toFixed(4) }}%
                                </td>
                                
                                <td class="linha" style="width: 10%;font-size:14px;"> 
                                  {{ isNaN(row.item.naotitular / row.item.carteira) ? '0.0000' : (row.item.naotitular / row.item.carteira).toFixed(4) }}%
                                </td>
                          </tr>
                      </template>
              </v-data-table>
              <span style="font-size: 12px;">
                * (%) C = Total de Óbitos / Total de Óbitos de Todas Carteiras <br/>
                ** (%) P = Total de Óbitos / Total da Carteira
              </span>

      <br/>
  
        <div class="d-flex justify-end" >
          <div style="width: 80px;">
            <span @click="download_xls"  style="color:orange; font-size: 48px; margin-right: 12px; cursor: pointer;">
              <i class="fas fa-file-excel"></i> 
            </span>
            
            <br/>
          
          </div>
        </div>  


    <br/>

    </base-material-card>
  
    </v-col>

  

  </v-row>


  </v-container>

</v-app> 
</template>
<script>
  import VueApexCharts from "vue-apexcharts";
  import FiltroComponent from "./components/FiltroSinistralidade.vue"; // Certifique-se de que o caminho esteja correto
  import Sinistralidade from '../../services/sinistralidade' 
  import { ContentLoader } from 'vue-content-loader'

  export default {
    name: 'Obitos',
    components: {
      apexcharts: VueApexCharts,
      FiltroComponent,
      ContentLoader,
    },

    created() {
      this.selectedStartDate = this.getFirstDayOf60YearsAgo(),
      this.selectedEndDate =  this.formatDateBR(new Date())
    },
    
    mounted() {
     this.carregarLoad(),
     this.dashboard();
    // this.handleFilterChange(this.selectMode, this.selectedStartDate, this.selectedEndDate, this.selectedStartDate2, this.selectedEndDate2);
    },
   
    data () {
      return {
        ranking_header: [
          { text: 'Plano', value: 'plano' },
          { text: 'Empresa', value: 'empresa' },
          { text: 'Carteira', value: 'carteira' },
          { text: 'Óbitos Titular', value: 'titular' },
          { text: '(%) C *', value: 'titular1' },
          { text: '(%) P **', value: 'titular2' },
          { text: 'Óbitos Dep e Agr.', value: 'naotitular' },
          { text: '(%) C', value: 'naotitular1' },
          { text: '(%) P', value: 'naotitular1' },

        ],

          lista_sinistro: [],
          lista_sinistro_grupo: [],
          produto: 'Todos',
          bool_03 : true,
          bool_02: true,
          total_carteira: 0,
          total_titular: 0,
          total_naotitular: 0,
          subTextAddDynamic: "Total Recebido:",
          recebido_acordo:0,
          qtd_recebido_acordo:0,
          criado_acordo: 0,
          animationInterval: null, // Intervalo para controlar a animação
          empresa: 'Todas',
          selectedCompany: "Todas",
          selectedStatus: "Todos",
          selectedType: "Todos",
          selectedOpcao: "Todas",
          opcoes: ["Todas","Campanha 50 Anos"],
          selectMode: "Vencimento",
          selectedStartDate : "",
          selectedEndDate : "",
          isLoading: false,
          modos: ["Data de Adesão", "Data de Falecimento"],
          modos2: ["SIM", "NÃO"],
          campos: 'N',
          categoria: [],
          showFilter: false,
          showProgress: false,
          showFilterDownload: false,
          empresas: ["CEMITERIO PREVIDA", "MEMORIAL PARQUE MACEIÓ", "PARQUE DO AGRESTE", "PREVIDA", "A FLOR DO CAFÉ", "Todas"],
          status: ["Todos", "Ativo", "Cancelado"],
          tipos: ["Todos", "Planos", "Planos Florais", "Planos Florais"],
          tipos_funerarios: [
                "Todos",
                "COMPLETO",
                "CONCESSÃO DE COLUMBÁRIO",
                "CONCESSÃO DE JAZIGOS",
                "CONCESSÃO DE OSSÁRIO",
                "CREMAÇÃO",
                "CREMAÇÃO COM CERIMÔNIA",
                "CREMAÇÃO SEM CERIMÔNIA",
                "FAMILIA",
                "INTEGRAL",
                "P23",
                "PARQUE TOTAL",
                "PF - AMPARO",
                "PROTEÇÃO",
                "PV - AMIGO",
                "PV - BRONZE",
                "PV - CREMAÇÃO COM CERIMÔNIA",
                "PV - DIAMANTE",
                "PV - EMPRESARIAL",
                "PV - EMPRESARIAL - EMPRESA",
                "PV - ESPECIAL",
                "PV - ESMERALDA",
                "PV - ESSENCIAL",
                "PV - IDEAL",
                "PV - MAIOR",
                "PV - MAIS",
                "PV - MASTER GOLD",
                "PV - MELHOR",
                "PV - PLANO PJ",
                "PV - PLUS",
                "PV - POLICIA MILITAR",
                "PV - PRATA",
                "PV - PREVIDA",
                "PV - PREVIPARQ DO AGRESTE",
                "PV - RUBI",
                "PV - SÃO MATHEUS",
                "URNAS CINERARIAS",
                "VENDA DE COLUMBÁRIO",
                "VENDA DE JAZIGOS - IMEDIATO",
                "VENDA DE JAZIGOS - PREVENTIVO",
                "VENDA DE JAZIGOS - TRANSITÓRIO"
              ],
           lista_nome_empresa:[],
      
      }
    },

   
    methods: {

        isDateValid(dateString) {
          // Verifica se a string é uma data válida
          const date = new Date(dateString);
          return !isNaN(date.getTime());
        },
        areDatesValid(dates) {
          // Verifica se todas as datas na matriz são válidas
          return dates.every(dateString => {
            const date = new Date(dateString);
            return !isNaN(date.getTime());
          });
        },


      filterData(valores) {

          this.carregarLoad(),
          this.isLoading = true;
          let nova_consulta = false;
          this.selectedType = valores.selectedType === "Jazigo" ? "Cemitérios" : valores.selectedType;
          this.selectedOpcao = valores.selectedOpcao;
          this.selectedCompany = valores.selectedCompany.join(",");
          this.selectMode = valores.selectMode;
          //this.selectMode = "Vencimento";
          //this.handleFilterChange(valores.selectMode, valores.selectedStartDate, valores.selectedEndDate, valores.selectedStartDate2, valores.selectedEndDate2);
          this.selectedStartDate = valores.selectedStartDate;
          this.selectedEndDate = valores.selectedEndDate;
          this.selectedStatus = valores.selectedStatus;
          this.produto = valores.selectedProduto;
          //this.gf01();
          this.dashboard();
         
      },

      
      filtrarTiposFunerarios(tipoFunerario) {
        const tiposPermitidos = "Todos";
        if (this.tipoFunerario === "Jazigo") {
           tiposPermitidos = ["CONCESSÃO DE COLUMBÁRIO", "CONCESSÃO DE JAZIGOS", "CONCESSÃO DE OSSÁRIO", "VENDA DE COLUMBÁRIO", "VENDA DE JAZIGOS - IMEDIATO", "VENDA DE JAZIGOS - PREVENTIVO", "VENDA DE JAZIGOS - TRANSITÓRIO"];
        }
        if (this.tipoFunerario === "Plano") {
           tiposPermitidos = ["COMPLETO",
                "FAMILIA",
                "INTEGRAL",
                "P23",
                "PARQUE TOTAL",
                "PF - AMPARO",
                "PROTEÇÃO",
                "PV - AMIGO",
                "PV - BRONZE",
                "PV - CREMAÇÃO COM CERIMÔNIA",
                "PV - DIAMANTE",
                "PV - EMPRESARIAL",
                "PV - EMPRESARIAL - EMPRESA",
                "PV - ESPECIAL",
                "PV - ESMERALDA",
                "PV - ESSENCIAL",
                "PV - IDEAL",
                "PV - MAIOR",
                "PV - MAIS",
                "PV - MASTER GOLD",
                "PV - MELHOR",
                "PV - PLANO PJ",
                "PV - PLUS",
                "PV - POLICIA MILITAR",
                "PV - PRATA",
                "PV - PREVIDA",
                "PV - PREVIPARQ DO AGRESTE",
                "PV - RUBI",
                "PV - SÃO MATHEUS"];
        }

        if (this.tipoFunerario === "Cremação") {
           tiposPermitidos = [ "CREMAÇÃO",
                "CREMAÇÃO COM CERIMÔNIA",
                "CREMAÇÃO SEM CERIMÔNIA",
                "URNAS CINERARIAS"];
        }

        if (this.tipoFunerario === "Todos" ||  this.tipoFunerario ===  "Funerária" || this.tipoFunerario ===  "Serviços Administrativos e Cemiteriais") {
           tiposPermitidos = [ "Todos"];
        }

        if (tiposPermitidos.includes(tipoFunerario)) {
          return true; // Mantém apenas os tipos permitidos
        } else {
          return false; // Remove os tipos não permitidos
        }
      },
     
      getFirstDayOfMonth() {
        const dataAtual = new Date();
        const primeiroDiaDoMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);
        return this.formatDateBR(primeiroDiaDoMes);
        },

        startAnimation(targetValue) {
            this.animationInterval = setInterval(() => {
              if (this.animatedValue < targetValue) {
                this.animatedValue += 1; // Atualiza o valor animado
              } else {
                clearInterval(this.animationInterval); // Interrompe a animação quando atingir o valor desejado
              }
            }, 10); // Intervalo em milissegundos entre cada atualização
          },


        formatDateBR(date) {
                if (!date) return '';
                const parsedDate = new Date(date);
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const year = parsedDate.getFullYear();
                return `${day}/${month}/${year}`;
            },

        formatDateBRAPI(date) {
                if (!date) return '';
                const parsedDate = new Date(this.formatDateBR(date));
                const year = parsedDate.getFullYear();
                const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                const day = String(parsedDate.getDate()).padStart(2, '0'); // Adiciona zero à esquerda, se necessário
                return `${year}-${month}-${day}`;
              },



      carregarLoad(){

         this.bool_03 = true
         this.bool_03 = true;

      },

      async dashboard() {

              this.isLoading = true;
              const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
              const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);

              //const formattedStartDate2 = converteDataParaFormatoISO(this.selectedStartDate2);
              //const formattedEndDate2 = converteDataParaFormatoISO(this.selectedEndDate2);

              const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);

                      if (diferencaEmDias > 25550) {
                        this.isLoading = false;
                        this.showFilter = true;
                        this.showProgress = false;
                        this.dias = 25550;
                        return 
                      }
                   
                      Sinistralidade.lista_sinistralidade(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode,  this.selectedOpcao, this.produto, this.selectedStatus).then(response => {

                          if(response.status === 200){
                            this.lista_sinistro = response.data;  
                          } 

                          }).catch(e => {    
                          }).finally(() => {
                            this.isLoading = false;
                            this.calcularDadosPorUsuario();
                          })  
       
                 
      },


      getFirstDayOf60YearsAgo() {
            const dataAtual = new Date();
            const anoAtual = dataAtual.getFullYear();
            const ano60Atras = anoAtual - 60;
            const primeiroDiaDoAno60Atras = new Date(ano60Atras, 0, 1); // Janeiro é representado como 0
            return this.formatDateBR(primeiroDiaDoAno60Atras);
        },
   

      download_xls() {

          this.showProgress = true;              
          const formattedStartDate = converteDataParaFormatoISO(this.selectedStartDate);
          const formattedEndDate = converteDataParaFormatoISO(this.selectedEndDate);
          const diferencaEmDias = this.calcularDiferencaEntreDatas(formattedStartDate, formattedEndDate);


        

          if (diferencaEmDias > 25550) {
                        this.isLoading = false;
                        this.showFilter = true;
                        this.showProgress = false;
                        this.dias = 25550;
                        return 
                      }

         Sinistralidade.lista_sinistralidade_xls(formattedStartDate, formattedEndDate, this.selectedCompany, this.selectedType, this.selectMode,  this.selectedOpcao, this.produto, this.selectedStatus)
            .then(response => {
                  if (response.status === 200) {
                  
                          const fileName = `sinistralidade_${Date.now()}.xlsx`;
                          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
                          const fileLink = document.createElement('a')
                          fileLink.href = fileURL
                          fileLink.setAttribute('download', fileName)
                          document.body.appendChild(fileLink)
                          fileLink.click()
                      
                  }
                })
                .catch(error => {
                  // Lida com erros
                  console.error('Erro ao baixar o arquivo Excel:', error);
                }) .finally(() => {
                  // Após o término do download, oculte o diálogo de progresso
                  this.showProgress = false;
                });
     

        },


      calcularDadosPorUsuario() {
           
          let totalcarteira = 0;
          let totaltitular = 0;
          let totalnao_titular = 0;

          this.lista_sinistro.forEach(item => {
              totalcarteira += parseFloat(item.carteira) || 0; // Converte para número e soma
              totaltitular += parseFloat(item.titular) || 0;
              totalnao_titular += parseFloat(item.naotitular) || 0;
          });


          this.total_carteira = totalcarteira,
          this.total_titular = totaltitular,
          this.total_naotitular = totalnao_titular

      },

      formatarMoeda(valor) {
        // Verifica se o valor é numérico
        if (isNaN(valor)) {
            return 'Valor inválido';
        }

        // Formata o valor como moeda brasileira (BRL)
        return valor.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        });
    },


    getStatus (status) {
            switch (status) {
            case 'PARQUE DAS FLORES': return 'orange darken-2'
            case 'CEMITERIO PREVIDA' : return 'blue lighten-1'
            case 'MEMORIAL PARQUE MACEIÓ' : return 'orange lighten-1'
            case 'PARQUE DO AGRESTE' : return 'cyan lighten-1'
            case 'PREVIDA' : return 'blue lighten-1'

              default: 'success'
            }
      },

      calcularComissao: function(empresa, resultado, comissao, original, pago) {
        
        if (empresa === "PREVIDA") {
          if (resultado < 0) {
            // Se o resultado for negativo, retorna a diferença entre pago e original
            return pago - original;
          } else {
            // Se o resultado não for negativo, retorna a comissão
            return comissao;
          }
        } else {
          // Se a empresa for diferente de "Previda", retorna a comissão
          return comissao;
        }
      },


      calcularDiferencaEntreDatas(dataInicial, dataFinal) {
                const data1 = new Date(dataInicial);
                const data2 = new Date(dataFinal);

                // Verifique se as datas são válidas
                if (isNaN(data1) || isNaN(data2)) {
                    return NaN; // Retorna NaN se uma das datas for inválida
                }

                const diferencaEmMilissegundos = data2 - data1;
                const diferencaEmDias = diferencaEmMilissegundos / (1000 * 60 * 60 * 24);

                return diferencaEmDias;
      },


        clear () {
          this.$refs.form.reset();
        },

    },
  }


   
  function formatMoeda(value) {
                      // Use o método toLocaleString com a opção style: 'currency' para formatar como moeda

                      if (value === undefined || value === null) {
                          return 'R$ 0,00'; // Ou você pode retornar 0 como número, se preferir.
                        }

                      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
                      //return 0
                      //return value
                  }

 
  function converteDataParaFormatoISO(dataNoFormatoBR) {
              const partes = dataNoFormatoBR.split('/');
              if (partes.length === 3) {
                const [dia, mes, ano] = partes;
                return `${ano}-${mes}-${dia}`;
              }
              return null; // Retorna null se a data estiver em um formato inválido
            }



</script>


<style scoped>
.v-progress-circular {
  margin: 1rem;
}

.custom-gray-background {
  height: 90px;
}

.row {
  display: flex;
  flex-wrap: wrap;
   flex: 0 1 auto !important; 
  margin: -12px;
}


/* Defina uma classe para preencher a largura horizontalmente e centralizar verticalmente */
.fill-width {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  display: flex;
}

.v-menu__content{
  box-shadow:none !important;
  top: 4px !important;
}
/* Centralize horizontalmente */
.justify-center {
  justify-content: center;
}

.gray-row {
  background-color: #e7f1ff; /* ou qualquer outra cor cinza que você preferir */
}
.cor-row {
  background-color: #90d34c21   ;
  transition: none !important; /* Desativa a transição de cor */

}


.linha{

    font-size: 11px !important;
    font-weight: bold;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }

  .no-hover-effect.v-data-table tbody tr:hover {
    color: #3055a4;
  }
.card-container {
  display: flex;
  justify-content: space-around; /* ou outro alinhamento que desejar */
}

</style>
